import React, { useMemo } from 'react'

import { useAuth } from '../lib/auth'
import { useUserAccessPolicy } from '../lib/auth/user-access-policy'
import { SanityUserRolesContext } from '../lib/sanity/context'
import { SanityUserRole, userRoleFromCategory } from '../lib/sanity/roles'
import { isUserCategory, userClaimIds } from '../utils/userClaims'

const SanityUserRolesContextProvider: React.FC = ({ children }) => {
  const { isLoading, user } = useAuth()
  const { canReadPharmacyContent } = useUserAccessPolicy()

  const userRoles = useMemo(() => {
    if (!user) {
      return [SanityUserRole.Anonymous]
    }

    const userCategory = user[userClaimIds.category] as unknown
    return [
      SanityUserRole.Authenticated,
      ...(isUserCategory(userCategory) ? [userRoleFromCategory(userCategory)] : []),
      ...(userCategory === 'practitioner' && !canReadPharmacyContent
        ? [SanityUserRole.CategoryPractitionerNonPharmacist]
        : []),
      ...(canReadPharmacyContent ? [SanityUserRole.ScopeReadPharmacyContent] : []),
    ]
  }, [user, canReadPharmacyContent])

  const contextValue = useMemo(
    () => ({
      isLoading,
      userRoles,
    }),
    [isLoading, userRoles],
  )

  return (
    <SanityUserRolesContext.Provider value={contextValue}>
      {children}
    </SanityUserRolesContext.Provider>
  )
}

export default SanityUserRolesContextProvider
