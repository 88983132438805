import { ApolloError, isApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { CartFragment } from '../../graphql/magento'
import { rebateWithAutoshipRemainder } from './config'

export const isCartWithAutoShip = (cart: CartFragment): boolean => {
  return cart.items != null
    ? !!cart.items.find((item) => {
        return item?.item_attributes?.dfh_order_type === 2
      })
    : false
}

export const isCartWithAutoShipAndRebate = (cart: CartFragment): boolean => {
  return cart.rebate?.value != null && cart.rebate.value > 0 && isCartWithAutoShip(cart)
}

export const getEligibleRebateAmount = (cart: CartFragment, currentBalance: number): number => {
  const cartRebateValue = cart.rebate?.value || 0
  const cartGrandTotalValue = cart.prices?.grand_total?.value || 0
  const orderTotal = cartRebateValue + cartGrandTotalValue
  return Math.min(
    isCartWithAutoShip(cart) ? orderTotal - rebateWithAutoshipRemainder : orderTotal,
    currentBalance,
  )
}

export const areCartsEqual = (cart1?: CartFragment, cart2?: CartFragment): boolean => {
  return (
    !!cart1 &&
    !!cart2 &&
    (Object.is(cart1, cart2) || JSON.stringify(cart1) === JSON.stringify(cart2))
  )
}

export const isCartNotFoundError = (
  error: ApolloError | Error | undefined,
  cartId?: string,
): boolean => {
  if (!error) {
    return false
  }
  if (!isApolloError(error)) {
    return false
  }
  return isCartNotFoundGraphQlErrors(error.graphQLErrors, cartId)
}

export const isCartNotFoundGraphQlErrors = (
  graphQLErrors?: readonly GraphQLError[],
  cartId?: string,
): boolean => {
  if (!graphQLErrors || graphQLErrors.length === 0) {
    return false
  }
  return graphQLErrors.some(({ message, extensions, path }): boolean => {
    if (cartId && message.includes(cartId)) {
      return true
    }
    if (
      message.includes('Could not find a cart with ID') ||
      message.includes("The cart isn't active")
    ) {
      return true
    }
    // Retain the two error checks below known from M2 2.3.x for backward compatibility
    if (extensions?.category !== 'graphql-no-such-entity') {
      return false
    }
    if (message.includes('Current user does not have an active cart')) {
      return true
    }
    return !!path && path.includes('cart')
  })
}

export const cartCoupons = (
  cart: CartFragment,
): NonNullable<CartFragment['applied_coupons']> | null =>
  cart.applied_coupons?.length ? cart.applied_coupons : null

export const cartCoupon = (cart: CartFragment): string | null => {
  const [firstCoupon] = cartCoupons(cart) || []
  return firstCoupon ? firstCoupon.code : null
}

export const formFieldLabelText = ({
  error,
  label,
}: {
  error: FieldError | undefined
  label: string | ReactNode
}): string | ReactNode => (error && error.message ? error.message : label)
